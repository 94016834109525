/* 955595->pink shade */

:root {
    --teal: #03DAC6;
    --pink: #E94560;
    --violet: #BB86FC;

    --primary: #11998e;
    --secondary: #38ef7d;
    --white: #fff;
    --gray: #9b9b9b;
}

.signUp-wrapper {
    /* background: #1A1A1A; */

    background: #05051e;
    color: #ffffff;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 9;
}

.form-container {
    width: 70%;
    max-width: 1400px;
    height: 70%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-container>.formleft-side {
    width: 50%;
    height: 34.4rem;
    background: #2C2C2C;
    color: #ffffff;
    padding: 0 2rem;
    background-image: url('https://img.freepik.com/free-vector/3d-growth-chart-diagram-with-shadow_1017-20818.jpg?t=st=1724062651~exp=1724066251~hmac=ee76c2b9e9e90e3d8c368b6f6aeb43e1ea7c3237338d6e04e813f0bb2ea7bba7&w=740'), linear-gradient(rgba(0, 0, 0), rgba(0, 0, 0));
    color: black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 2rem;
    position: relative;
    box-shadow: 1px 0px 7px #eedfdf73;
    border-radius: 0.7rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.form-container>.formleft-side::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: #282727; */
    /* background: #020202c9; */

    background: rgb(85 106 138 / 88%);
    color: #ffffff;
    z-index: 1;
    border-radius: 0.7rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.sign-in-btn {
    color: #000;
    z-index: 9;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 1.4rem;
}

.sign-in-btn>a {
    font-size: 1.2rem;
    font-weight: 700;
    background: transparent;
    color: #000000;
    padding: 0.6rem 2rem;
    padding-left: 0.6rem;
    text-decoration: none;
    text-transform: uppercase;
    /* background: linear-gradient(135deg, var(--teal), var(--pink)); */

    background: linear-gradient(135deg, #955595, #399580);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

/* Right-Side */

.form-container>.formright-side {
    width: 50%;
    height: 34.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    background: #ffffff;
    box-shadow: 1px 0px 7px #ff0f0f;
    border-radius: 0.7rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-bottom: 1rem;
}

.form-container>.formright-side>h2 {
    font-size: 2.7rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    margin-top: 1rem;
    /* background: linear-gradient(135deg, var(--teal), var(--pink)); */

    background: linear-gradient(135deg, #955595, #399580);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    background-clip: text;
    color: transparent;
}

.form-container>.formright-side>form,
.form-container>.formright-side>form>.input-container {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: baseline;
    gap: 0.4rem 1rem;
}

.form-container>.formright-side>form {
    padding: 0 2rem;
}

.form-container>.formright-side>form>.input-container>input {
    width: 48%;
    padding: 1.2rem;
    border: none;
    outline: none;
    margin: 0.4rem 0;
    border-radius: 0.6rem;
}

.formright-side {
    height: 100%;
}

.formright-side>form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: baseline;
    width: 100%;
    height: 80%;
}

.form-container>.formright-side>form>button {
    /* background: #E94560; */
    /* background: linear-gradient(135deg, var(--teal), var(--pink)); */
    /* background: linear-gradient(270deg, var(--teal), var(--pink)); */

    background: linear-gradient(135deg, #955595, #399580);
    color: white;
    border: none;
    outline: none;
    /* width: 30%; */
    width: auto;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 0.4rem;
    margin-top: 2rem;
    text-transform: uppercase;
}

.form-container>.formright-side>form>button:hover {
    background: linear-gradient(135deg, var(--pink), var(--violet), var(--teal));
    box-shadow: 1px 1px 7px #a3a0a0;
    scale: 1;
    /* background:  #BB86FC ; */
    font-weight: bold;
}

/* Styling of Input Fields */

.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 50%;
}

.form__field {
    width: 100%;
    border: 0;
    border-bottom: 2px solid var(--gray);
    outline: 0;
    font-size: 1.3rem;
    /* color: var(--white); */
    color: #000;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}

.form__field::placeholder {
    color: transparent;
}

.form__field:placeholder-shown~.form__label {
    font-size: 1.2rem;
    cursor: text;
    top: 20px;
}

.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: var(--gray);
}

.form__field:focus~.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: var(--primary);
    font-weight: 700;
}

.form__field:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, var(--primary), var(--secondary));
    border-image-slice: 1;
}

.form__field:required,
.form__field:invalid {
    box-shadow: none;
}

.error-message {
    color: red;
    font-size: 0.7rem;
    font-weight: 600;
}

.form-select {
    width: 100%;
    text-align: left;
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* RESPONSIVE MEDIA QUERY */

@media screen and (max-width: 1200px) {
    .form-container>.formright-side>h2 {
        font-size: 2rem;
    }

    .form-container>.formright-side>form {
        padding: 0 1rem;
    }

    .form__field:placeholder-shown~.form__label {
        font-size: 1rem;
    }

    .form__group {
        padding: 10px 0 0;
    }

    .sign-in-btn {
        font-size: 1.2rem;
    }

    .userFeedback-main-container>.userFeedback-content {
        font-size: 1rem;
    }
}

@media screen and (max-width: 993px) {
    .signUp-wrapper {
        height: auto;
        min-height: 100vh;
    }

    .form-container {
        width: 100%;
        height: auto;
        flex-direction: column;
    }

    .form-container>.formleft-side {
        width: 60%;
        height: auto;
        padding: 1.2rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .form-container>.formright-side {
        width: 60%;
        height: auto;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .form-container>.formright-side>h2 {
        font-size: 2rem;
    }

    .form-container>.formright-side>form {
        padding: 0 1rem;
    }

    .form__field:placeholder-shown~.form__label {
        font-size: 1rem;
    }

    .form__group {
        padding: 10px 0 0;
    }

    .sign-in-btn {
        font-size: 1.1rem;
    }

    .form-container>.formright-side>form>button {
        padding: 0.88rem 2rem;
        margin-top: 1rem;
    }

    .userFeedback-main-container>.userFeedback-content {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .form-container {
        padding: 1rem 0.4rem;
    }

    .form-container>.formleft-side {
        width: 80%;
        height: auto;
        padding: 1.2rem;
    }

    .form-container>.formright-side {
        width: 80%;
        height: auto;
    }
}

@media screen and (max-width: 500px) {
    .form-container>.formleft-side {
        width: 92%;
        height: auto;
        padding: 1.2rem;
    }

    .form-container>.formright-side {
        width: 92%;
        height: auto;
    }
}